const PROD = 'https://api-h360.toro.com/api';
const STAGE = 'https://stage-api-h360.toro.com/api';
const DEV = 'https://dev-api-h360.toro.com/api';
const QA = 'https://qa-api-h360.toro.com/api';
const GUEST = 'https://qa-api-beta-h360.toro.com/api';
const GUEST_QA = 'https://qa-api-beta-h360.toro.com/api';
const GUEST_DEV = 'https://dev-api-beta-h360.toro.com/api';
const GUEST_STAGING = 'https://stage-api-beta-h360.toro.com/api';
const GUEST_PRODUCTION = 'https://api-beta-h360.toro.com/api';
export const DEV_V2 = 'https://dev-api2-h360.toro.com/api';

const paymetricUrl = {
  dev: 'https://cert-xiecomm.paymetric.com/',
  production: 'https://xiecomm.paymetric.com/',
  stage: 'https://cert-xiecomm.paymetric.com/',
  qa: 'https://cert-xiecomm.paymetric.com/'
};

export default {
  config: () => {
    switch (process.env.REACT_APP_STAGE) {
      case 'production':
        return {
          baseUrl: PROD,

          paymetricUrl: paymetricUrl.production,
          env: 'production'
        };
      case 'staging':
        return {
          baseUrl: STAGE,

          paymetricUrl: paymetricUrl.stage,
          env: 'staging'
        };

      case 'develop':
        return {
          baseUrl: DEV,
          baseUrl_v2: DEV_V2,
          paymetricUrl: paymetricUrl.dev,
          env: 'develop'
        };

      case 'qa':
        return {
          baseUrl: QA,

          paymetricUrl: paymetricUrl.qa,
          env: 'qa'
        };
      case 'guest':
        return {
          baseUrl: GUEST,

          paymetricUrl: paymetricUrl.qa,
          env: 'guest'
        };
      case 'guest_qa':
        return {
          baseUrl: GUEST_QA,
          paymetricUrl: paymetricUrl.qa,
          env: 'guest_qa'
        };
      case 'guest_dev':
        return {
          baseUrl: GUEST_DEV,
          paymetricUrl: paymetricUrl.qa,
          env: 'guest_dev'
        };
      case 'guest_staging':
        return {
          baseUrl: GUEST_STAGING,
          paymetricUrl: paymetricUrl.qa,
          env: 'guest_staging'
        };
      case 'guest_production':
        return {
          baseUrl: GUEST_PRODUCTION,
          paymetricUrl: paymetricUrl.qa,
          env: 'guest_production'
        };
      case 'deploy':
        return {
          baseUrl: 'https://api-beta-h360.toro.com/api',
          baseUrl_v2: 'https://api-beta-h360.toro.com/api',
          env: 'guest_production',
          paymetricUrl: 'https://xiecomm.paymetric.com/'
        };
      default:
        return {
          baseUrl: DEV,

          paymetricUrl: paymetricUrl.dev,
          env: 'develop'
        };
    }
  },
  defaultRoute: '/dashboard'
};
